<template>
  <!--begin::RolesList-->
  <div class="row gy-5 g-xl-8">
    <RolesList widget-classes="card-xxl-stretch mb-5 mb-xl-8"></RolesList>
  </div>
  <!--end::RolesList-->
</template>

<script lang="js">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import RolesList from "@/components/settings/RolesIndex.vue";

export default defineComponent({
  name: "RolesView",
  components: {
    RolesList
  },
  mounted() {
    setCurrentPageTitle("Roles");
  }
});
</script>
