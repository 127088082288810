
import { defineComponent } from "vue";

export default defineComponent({
  name: "RolesDataTable",
  props: {
    tableData: Object
  },
  methods: {
    emitEditEvent(entry) {
      const idsOfAllPermissions = entry.permission.map(
        permission => permission.id
      );
      const rolePayload = {
        id: entry.id,
        title: entry.title,
        description: entry.description,
        permissionIds: idsOfAllPermissions
      }
      this.$emit("Edit", rolePayload);
    },
    openDeleteModal(entry) {
      const deleteModalPayload = {
        id: entry.id,
        title: entry.title
      };
      this.$emit("deleteButton", deleteModalPayload);
    }
  }
});
