
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import RolesDataTable from "@/components/settings/Tables/RolesDataTable.vue";
import { mapActions, mapGetters } from "vuex";
import PermissionsModal from "@/components/general/PermissionsModal.vue";
import DeleteModal from "@/components/general/DeleteModal.vue";

export default defineComponent({
  name: "RolesOverview",
  components: {
    RolesDataTable,
    LoadingSpinner,
    PermissionsModal,
    DeleteModal
  },
  data() {
    return {
      currentlySelectedRole: {
        id: 0,
        title: "",
        description: "",
        permissionIds: []
      },
      isThereAModalActive: false,
      modalSetup: {}
    };
  },
  methods: {
    ...mapActions("RolesModule", [
      "fetchData",
      "createNew",
      "update",
      "destroyData"
    ]),
    openAddNewModal() {
      const setupAddNewModal = {
        title: "Add new role",
        valueOfDropdown: "",
        buttonText: "Add new",
        actionType: "AddNew",
        resetMultiSelectValue: true,
        firstInputValue: "",
        secondInputValue: ""
      };
      this.modalSetup = setupAddNewModal;
      this.isThereAModalActive = true;
    },
    saveDataFromModal(dataFromModal) {
      if (dataFromModal.actionType === "Edit") {
        this.currentlySelectedRole.title = dataFromModal.valueOfFirstInput;
        this.currentlySelectedRole.description = dataFromModal.valueOfSecondInput;
        this.currentlySelectedRole.permissionIds = dataFromModal.valueOfDropdown;
        this.update(this.currentlySelectedRole);
        this.isThereAModalActive = false;
        this.modalSetup = {};
      } else {
        const newRole = {
          title: dataFromModal.valueOfFirstInput,
          description: dataFromModal.valueOfSecondInput,
          permission: dataFromModal.valueOfDropdown
        };
        this.createNew(newRole);
        this.isThereAModalActive = false;
        this.modalSetup = {};
      }
    },
    openEditModal(rolePayload) {
      this.currentlySelectedRole = rolePayload;
      const setupEditModal = {
        title: "Edit role",
        valueOfDropdown: this.currentlySelectedRole.permissionIds,
        buttonText: "Save changes",
        actionType: "Edit",
        resetMultiSelectValue: true,
        firstInputValue: this.currentlySelectedRole.title,
        secondInputValue: this.currentlySelectedRole.description
      };
      this.modalSetup = setupEditModal;
    },
    closeModal() {
      this.isThereAModalActive = false;
      this.modalSetup = {};
    },
    openDeleteModal(deleteModalPayload) {
      this.currentlySelectedRole.title = deleteModalPayload.title;
      this.currentlySelectedRole.description = deleteModalPayload.description;
      this.currentlySelectedRole.id = deleteModalPayload.id;
      this.isThereAModalActive = true;
    },
    deleteSelectedRole() {
      this.destroyData(this.currentlySelectedRole);
      this.isThereAModalActive = false;
      this.currentlySelectedRole.title = "";
      this.currentlySelectedRole.description = "";
      this.currentlySelectedRole.id = 0;
    }
  },
  computed: {
    ...mapGetters("RolesModule", ["getData", "loading"])
  },
  mounted() {
    this.fetchData();
  }
});
