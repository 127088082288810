
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "EditModal",
  props: {
    setup: {
      type: Object,
      require
    }
  },
  data() {
    return {
      values: {
        valueOfFirstInput: "",
        valueOfSecondInput: "",
        valueOfDropdown: "",
        actionType: "",
        resetMultiSelectValue: null
      }
    };
  },
  methods: {
    ...mapActions("ListsModule", ["fetchAllPermissions"]),
    emitSaveButtonEvent() {
      if (this.setup != undefined && this.values.valueOfFirstInput == "") {
        this.values.valueOfFirstInput = this.setup.firstInputValue;
        this.values.valueOfSecondInput = this.setup.secondInputValue;
      }
      this.$emit("save", this.values);
    }
  },
  computed: {
    ...mapGetters("ListsModule", ["getPermissions"])
  },
  watch: {
    setup() {
      if (this.setup != undefined) {
        this.values.valueOfFirstInput = this.setup.firstInputValue;
        this.values.valueOfSecondInput = this.setup.secondInputValue;
        this.values.valueOfDropdown = this.setup.valueOfDropdown;
        this.values.actionType = this.setup.actionType;
      }
    }
  },
  mounted() {
    this.fetchAllPermissions();
  }
});
